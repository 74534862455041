body{
    margin: 0;
}

.App {
    min-height: 100vh;
    position: relative;
}

#homeHeader,
.headerStrip {
    margin-bottom: -75px;
}

#homeHeader {
    width: 100%;
    height: 315px;
}

.headerStrip{
    height: 80px;
}

#cover {
    width: 960px;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
    background: url(./assets/cover.png) no-repeat 0 0;
    background-size: contain;
}

.avatar {
    display: block;
    width: 150px;
    height: 150px;
    border-radius: 75px;
    margin: 0 auto;
    background-size: cover;
    border: 2px solid #fff;
}

.avatar-img {
    background: #c7c8c9 url(./assets/avatar.png) no-repeat 0 0;
}
.fourOhFour .avatar-img {
    transform: rotate(180deg)
}

.dice {
    background: #69A84F url(./assets/dice.png) no-repeat;
}

.dice-1 {background-position: 0 0;}
.dice-2 {background-position: -150px 0;}
.dice-3 {background-position: -300px 0;}
.dice-4 {background-position: -450px 0;}

.dice-5 {background-position: 0 -150px;}
.dice-6 {background-position: -150px -150px;}
.dice-7 {background-position: -300px -150px;}
.dice-8 {background-position: -450px -150px;}

.dice-9 {background-position: 0 -300px;}
.dice-10 {background-position: -150px -300px;}
.dice-11 {background-position: -300px -300px;}
.dice-12 {background-position: -450px -300px;}

.dice-13 {background-position: 0 -450px;}
.dice-14 {background-position: -150px -450px;}
.dice-15 {background-position: -300px -450px;}
.dice-16 {background-position: -450px -450px;}

.dice-17 {background-position: 0 -600px;}
.dice-18 {background-position: -150px -600px;}
.dice-19 {background-position: -300px -600px;}
.dice-20 {background-position: -450px -600px;}

.dice-21 {background-position: 0 -750px;}
.dice-22 {background-position: -150px -750px;}
.dice-23 {background-position: -300px -750px;}
.dice-24 {background-position: -450px -750px;}


.navWrapper {
    text-align: center;
    width: fit-content;
    margin: 0 auto;
}

.navWrapper a {
    text-decoration: none;
    color: #6d6e71;
    font-family: "proxima-nova",sans-serif;
    font-weight: 800;
    font-size: 1.5rem;
}

.navWrapper a:hover {
    color: #69A84F;
}

#logo .logo {
    display: block;
    background: url(./assets/logo.png) no-repeat 0 0;
    width: 67px;
    height: 50px;
    margin: 10px auto;
    background-size: cover;
}

#logo h1{
    margin: 0;
    font-weight: 800;
    font-size: 1.5rem;
}

span.parenthesis {
    position: relative;
    top: -2px;
}

#logo:hover .logo {
    background-position-y: -50px;
}

.center{
	text-align: center;
}

.social a{
    margin: 10px;
    display: inline-block;
    width: 50px;
    height: 50px;
    list-style: none;
    background: url(./assets/socials.png) no-repeat 0 0;
}

.social p{
    float: right;
}

.social a span{
    position: relative;
    left: -9999px;
}

.social-github a{
    background-position-x: -50px;
}

.social-email a{
    background-position-x: -100px;
}

.social-github a:hover,
.social-linkedin a:hover,
.social-email a:hover{
    background-position-y: -50px;
}

.markdown {
    padding: 35px;
    border-radius: 15px;
    margin: 25px 0;
    overflow: overlay;
}

.markdown pre {
    padding: 15px;
    overflow: overlay;
}

.markdown img {
    max-width: 100%;
}

.previews .markdown{
    margin: 0;
    border: 5px solid;
}

.previews,
.about {
    margin: 25px auto;
}
.about .col {
    padding: 20px;
}
.about hr,
.about {
    margin: 50px 0;
}
.about .illustration {
    text-align: center;
}
.about img {
    margin: 0 auto;
}

.markdown table {
    max-width: 100%;
}

.markdown td,
.markdown th {
    border: 1px solid;
    padding: 2px 10px;
}

.markdown .social {
    float: left;
    position: relative;
    top: -15px;
    left: -10px;
    margin-bottom: -10px;
}

.active{
    color: #69A84F !important;
    /* border-bottom: 3px solid #fff !important; */
}

.filter-icon {
    margin: 0 10px;
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url(./assets/filter.png) no-repeat 0 0;
}

.filter button {
    margin: 0 10px 10px 10px;
    display: inline-block;
    border: none;
    background: transparent;
    padding: 0;
    vertical-align: top;
    color: #c7c8c9;

}

.darkModeSwitch{
    margin-bottom: 15px;
    position: absolute;
    bottom: 0px;
    background-color: #1b2f58;
    padding: 5px 10px 5px 60px;
    color: #fff;
}

@media only screen and (max-width: 959px) {
    #homeHeader {
        height: 40vw;
    }
}









@media (prefers-color-scheme: dark) {
    .App {
        background: #222;
        color: #fff;
    }
    
    #homeHeader,
    .headerStrip {
        background: #111;
    }
    
    .markdown {
        background: #111511;
        color: #fff;
    }
    
    .markdown pre {
        background: #000500;
    }
    
    .markdown code {
        color: #69A84F;
    }
    
    .previews .markdown{
        border: 5px solid #222;
    }
    
    .markdown td,
    .markdown th {
        border: 1px solid #000500;
    }
    
    .markdown th,
    .markdown tr:nth-child(even) {
        background-color: #151a15;
    }
    
    .markdown tr:nth-child(odd) {
        background-color: #111511;
    }
}

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
    .App {
        background: #fff;
        color: #000;
    }
    
    #homeHeader,
    .headerStrip {
        background: #c7c8c9;
    }
    
    .markdown {
        background: #eff2ef;
        color: #000;
    }
    
    .markdown pre {
        background: #dde2dd;
    }
    
    .markdown code {
        color: #31a500;
    }
    
    .previews .markdown{
        border: 5px solid #fff;
    }
    
    .markdown td,
    .markdown th {
        border: 1px solid #dde2dd;
    }
    
    .markdown th,
    .markdown tr:nth-child(even) {
        background-color: #e4e7e4;
    }
    
    .markdown tr:nth-child(odd) {
        background-color: #eff2ef;
    }
}





/* dark mode */

.darkMode {
    background: #222;
    color: #fff;
}

.darkMode #homeHeader,
.darkMode .headerStrip {
    background: #111;
}

.darkMode .markdown {
    background: #111511;
    color: #fff;
}

.darkMode .markdown pre {
    background: #000500;
}

.darkMode .markdown code {
    color: #69A84F;
}

.darkMode .previews .markdown{
    border: 5px solid #222;
}

.darkMode .markdown td,
.darkMode .markdown th {
    border: 1px solid #000500;
}

.darkMode .markdown th,
.darkMode .markdown tr:nth-child(even) {
    background-color: #151a15;
}

.darkMode .markdown tr:nth-child(odd) {
    background-color: #111511;
}







/* light mode */

.lightMode {
    background: #fff;
    color: #000;
}

.lightMode #homeHeader,
.lightMode .headerStrip {
    background: #c7c8c9;
}

.lightMode .markdown {
    background: #eff2ef;
    color: #000;
}

.lightMode .markdown pre {
    background: #dde2dd;
}

.lightMode .markdown code {
    color: #31a500;
}

.lightMode .previews .markdown{
    border: 5px solid #fff;
}

.lightMode .markdown td,
.lightMode .markdown th {
    border: 1px solid #dde2dd;
}

.lightMode .markdown th,
.lightMode .markdown tr:nth-child(even) {
    background-color: #e4e7e4;
}

.lightMode .markdown tr:nth-child(odd) {
    background-color: #eff2ef;
}